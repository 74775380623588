import Goalkeeper from './Goalkeeper';
import GoalkeeperFigure from './GoalkeeperFigure';
import Timer from './Timer';
import React, { useState, useEffect } from 'react';
import DOMPurify from 'dompurify';

const GOALKEEPER_PRODUCT_NAME = 'Turtle Goalkeeper'
const GOALKEEPER_ITEM_PRICE = 25;
const GOALKEEPER_FIGURE_PRODUCT_NAME = 'Turtle Figure'
const GOALKEEPER_FIGURE_PRICE = 10;
const GOALKEEPER_FIGURE_THREAD_PRICE = 12;
const TIMER_ITEM_PRICE = 8;
const ORDER_DETAILS_GOALKEEPER_LINE_ITEM_FORMAT = '{quantity} x Turtle Goalkeeper ({figureColor}, {rodAndHandle})';
const ORDER_DETAILS_GOALKEEPER_FIGURE_LINE_ITEM_FORMAT = '{quantity} x Turtle Figure only ({figureColor}, {figureType})';
const ORDER_DETAILS_TIMER_LINE_ITEM_FORMAT = '{quantity} x Timer ({timerColor})';

const Buy = () => {
  const [csrfToken, setCsrfToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [deliveryOption, setDeliveryOption] = useState('custom');
  const [customAddress, setCustomAddress] = useState('');
  const [comment, setComment] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('goalkeeper');
  const [orderItems, setOrderItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceString, setTotalPriceString] = useState('€0');
  const [statusMessage, setStatusMessage] = useState('');

  // Fetch CSRF token on initial page load.
  useEffect(() => {
    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''; 
    fetch(`${baseUrl}/api/hello`, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        setCsrfToken(data.hello);
        setIsLoading(false);
      });
  }, []);

  // Update the total price string whenever the price changed.
  useEffect(() => {
    const formattedPrice = `€${totalPrice}`;
    setTotalPriceString(
      deliveryOption === 'custom' ? `${formattedPrice} + shipping costs` : formattedPrice
    );
  }, [totalPrice, deliveryOption]); 

  // Add another item to the shopping cart.
  const handleAddItem = (productType, productData) => {
    if (productType === 'goalkeeper') {
      const { quantity, figureColor, rodType, handleType } = productData;
      if (quantity && figureColor && rodType && handleType) {
        setOrderItems([...orderItems, { 
          "product": GOALKEEPER_PRODUCT_NAME,
          "quantity": quantity, 
          "figureColor": figureColor, 
          "rodType": rodType, 
          "handleType": handleType 
        }]);
        setTotalPrice(prevTotalPrice => prevTotalPrice + quantity * GOALKEEPER_ITEM_PRICE);
      }   
    } else if (productType === 'goalkeeper-figure') {
        const { quantity, figureColor, figureType } = productData;
        if (quantity && figureColor && figureType) {
          setOrderItems([...orderItems, { 
            "product": GOALKEEPER_FIGURE_PRODUCT_NAME,
            "quantity": quantity, 
            "figureColor": figureColor, 
            "figureType": figureType 
          }]);
          if (figureType === 'Standard') {
            setTotalPrice(prevTotalPrice => prevTotalPrice + quantity * GOALKEEPER_FIGURE_PRICE);
          } else {
            setTotalPrice(prevTotalPrice => prevTotalPrice + quantity * GOALKEEPER_FIGURE_THREAD_PRICE);
          }
        }       
    } else if (productType === 'timer') {
      const { quantity, timerColor } = productData;
      if (quantity && timerColor) {
        setOrderItems([...orderItems, { 
          "product": "Timer",
          "quantity": quantity, 
          "timerColor": timerColor,
        }]);
        setTotalPrice(prevTotalPrice => prevTotalPrice + quantity * TIMER_ITEM_PRICE);
      }
    }
  };

  // Remove an item from the shopping cart.
  const handleRemoveItem = index => {
    const removedItem = orderItems[index];
    setOrderItems(orderItems.filter((_, i) => i !== index));
    // Determine the price based on the product type
    const itemPrice = removedItem.product === "Timer" ? TIMER_ITEM_PRICE : removedItem.product === GOALKEEPER_PRODUCT_NAME ? GOALKEEPER_ITEM_PRICE : removedItem.figureType === 'Standard' ? GOALKEEPER_FIGURE_PRICE : GOALKEEPER_FIGURE_THREAD_PRICE;
    setTotalPrice(prevTotalPrice => prevTotalPrice - removedItem.quantity * itemPrice);
  };

  // Place an order comprised of all items in the shopping cart.
  const handlePlaceOrder = () => {
    if (!name.trim() || !email.trim() || !orderItems.length) {
      setStatusMessage('Please fill in all required fields.');
      return;
    }

    if (!/^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email)) {
      setStatusMessage('Please enter a valid email address.');
      return;
    }

    const shippingAddress = deliveryOption === 'custom' 
      ? customAddress.trim() || ''
      : 'tournament-' + document.getElementById('deliveryOption').options[document.getElementById('deliveryOption').selectedIndex].text;

    if (!shippingAddress) {
      setStatusMessage('Please enter a shipping address.')
      return; // Stop if there's no valid shipping address
    }

    // Contact the server to place the order.
    setStatusMessage('Placing order...');
    const emailParams = {
      buyer_name: DOMPurify.sanitize(name),
      buyer_email: DOMPurify.sanitize(email),
      address: DOMPurify.sanitize(shippingAddress),
      order_items: orderItems,
      comment: DOMPurify.sanitize(comment.trim() || ''),
      _csrf: csrfToken,  // Include the CSRF token in the request body
    };
    const baseUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : ''; 
    fetch(`${baseUrl}/api/order`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json' 
      },
      body: JSON.stringify(emailParams)  // Send emailParams in the request body
    })
    .then(response => {
      console.log(response);
      if (response.ok) {
        setStatusMessage('Order placed successfully!');
      } else {
        setStatusMessage('Please try again later. The server is down!');
      }
    });   
  };

  return (
    <div>
      <h1>Online Shop</h1>

      <label htmlFor="name">Your Name:</label><br />
      <input type="text" id="name" value={name} onChange={e => setName(e.target.value)} /><br /><br />

      <label htmlFor="email">Your Email:</label><br />
      <input type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} /><br /><br />

      <label htmlFor="deliveryOption">Choose Delivery option:</label><br />
      <select id="deliveryOption" value={deliveryOption} onChange={e => setDeliveryOption(e.target.value)}>
        <option value="custom">Custom address</option>np
        <option value="paris">MJ of Paris (Jan 25 - Jan 26)</option>np
        <option value="melbourne">GP of Melbourne (Feb 8 - Feb 9)</option>np
        <option value="vienna">GP of Vienna (Apr 5 - Apr 6)</option>np
        <option value="bormla">GP of Bormla (May 3 - May 4)</option>np
        <option value="messina">GP of Messina (Jun 14 - Jun 15)</option>np
      </select><br /><br />

      {deliveryOption === 'custom' && (
        <>
          <label htmlFor="customAddress">Shipping address:</label><br />
          <textarea id="customAddress" value={customAddress} onChange={e => setCustomAddress(e.target.value)} />
          <br />
          <span className="shipping-cost">
            Estimated costs:<br/>
            Europe &euro;12,15 - Rest of the World &euro;18,95 (for quantities &le; 4)<br/>
            Europe &euro;16,85 - Rest of the World &euro;28,15 (for quantities &ge; 5)
          </span>
          <br /><br />
        </>
      )}

      <div className="product-tabs">
        <div 
            className={`product-tab ${selectedProduct === 'goalkeeper' ? 'active' : ''}`} 
            onClick={() => setSelectedProduct('goalkeeper')}
        >
          Goalkeeper &euro;{GOALKEEPER_ITEM_PRICE}
        </div>
        <div 
            className={`product-tab ${selectedProduct === 'goalkeeper-figure' ? 'active' : ''}`} 
            onClick={() => setSelectedProduct('goalkeeper-figure')}
        >
          Figure only &euro;{GOALKEEPER_FIGURE_PRICE} / &euro;{GOALKEEPER_FIGURE_THREAD_PRICE}
        </div>
        <div 
            className={`product-tab ${selectedProduct === 'timer' ? 'active' : ''}`} 
            onClick={() => setSelectedProduct('timer')}
        >
          Timer &euro;{TIMER_ITEM_PRICE}
        </div>
      </div>

      {selectedProduct === 'goalkeeper' && (
        <div className="product-details">
          <Goalkeeper onAddItem={(data) => handleAddItem('goalkeeper', data)} />
        </div>
      )}
      {selectedProduct === 'goalkeeper-figure' && (
        <div className="product-details">
          <GoalkeeperFigure onAddItem={(data) => handleAddItem('goalkeeper-figure', data)} />
        </div>
      )}
      {selectedProduct === 'timer' && (
        <div className="product-details">
          <Timer onAddItem={(data) => handleAddItem('timer', data)} />
        </div>
      )}

      <h2>Shopping Cart</h2>
      <ul>
        {orderItems.length === 0 ? (
          <li>Your cart is empty</li>
        ) : (
          orderItems.map((item, index) => (
            <li key={index}>
              <span style={{ marginRight: '1rem' }}>
                {item.product === GOALKEEPER_PRODUCT_NAME
                  ? ORDER_DETAILS_GOALKEEPER_LINE_ITEM_FORMAT
                      .replace('{quantity}', item.quantity)
                      .replace('{figureColor}', item.figureColor)
                      .replace('{rodAndHandle}', item.rodType + ", " + item.handleType)
                  : item.product === GOALKEEPER_FIGURE_PRODUCT_NAME
                    ? ORDER_DETAILS_GOALKEEPER_FIGURE_LINE_ITEM_FORMAT
                        .replace('{quantity}', item.quantity)
                        .replace('{figureColor}', item.figureColor)
                        .replace('{figureType}', item.figureType)
                    : ORDER_DETAILS_TIMER_LINE_ITEM_FORMAT
                        .replace('{quantity}', item.quantity)
                        .replace('{timerColor}', item.timerColor)} 
              </span>
              <button onClick={() => handleRemoveItem(index)}>X</button>
            </li>
          ))
        )}
      </ul>

      {/* Total price and shipping information */}
      {orderItems.length > 0 && (
        <p>Total price: {totalPriceString}</p>
      )}

      {/* Comment textbox */}
      <label htmlFor="comment">Additional comment:</label><br />
      <textarea id="comment" value={comment} onChange={e => setComment(e.target.value)} />
      <br /><br />

      {selectedProduct === 'goalkeeper' && (
        <div className="warranty">Note: Attempts to remove figure from rod voids warranty.</div>
      )}      

      <div className="order">
        <button onClick={handlePlaceOrder} disabled={isLoading || orderItems.length === 0}>
          Place Order
        </button>

        {statusMessage && (
          <p className={`status ${statusMessage.includes('success') ? 'success' : statusMessage.includes('Please') ? 'error' : ''}`}>
            {statusMessage}
          </p>
        )}
      </div>

      <p className="impressum">
        <a href="/impressum">Impressum</a>
      </p>
    </div>
  );
};

export default Buy;
