import React from 'react';

const Home = () => (
    <div>
        <h1>FISTF Conforming and Approved <br /> <span className="highlight">„Turtle“</span> Goalkeeper</h1>
        <img src="turtle.jpg" width={500} alt="Turtle goalkeeper photos" />

        <p style={{ textAlign: 'center' }}>Figure and rod has the maximum size to fit through FISTF measurement tool!</p>
        <p style={{ textAlign: 'center' }}>3D printed plastic keeper figure and handle</p>

        <h2>Figure measurements:</h2>
        <ul>
            <li>Width: 21mm (at the top), 13mm (at the bottom)</li>
            <li>Thickness: 6mm</li>
            <li>M4 Metal Thread optional (figure only)</li>
        </ul>

        <h2>Rod:</h2>
        <ul>
            <li>Diameter: 4,5mm</li>
            <li>Material: Carbon (colour: anthrazit)</li>
        </ul>

        <h2>Handles:</h2>
        <ul>
            <li>Airplane (length: 58mm)</li>
            <li>Hexagonal (diameter: 15mm, length: 80mm)</li>
            <li>Hexagonal (diameter: 20mm, length: 80mm)</li>
        </ul>

        <h2>Total length:</h2>
        <ul>
            <li>&#126;22,8cm with Airplane handle</li>
            <li>&#126;24,9cm with Hexagonal handle</li>
        </ul>

        <h2>Total weight:</h2>
        <ul>
            <li>&#126;23g with carbon rod</li>
        </ul>

        <h2 className="highlight">
            Price excluding postage: &euro;25<br />
        </h2>

        <h2>Contact:</h2>
        <p>
            Christian Haas via FB messenger or{' '}
            <a href="mailto:office@tablefootball.shop">office@tablefootball.shop</a>
        </p>

        <p className="impressum">
          <a href="/impressum">Impressum</a>
        </p>
    </div>
);

export default Home;