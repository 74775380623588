import React, { useState } from 'react';

// Image paths for all goalkeeper attributes
const imagePaths = {
    figureColor: {
      'Light grey': {
        'Standard': 'color_grey.jpg',
        'M4 Metal Thread': 'color_grey_with_thread.jpg', 
      },
      'White': {
        'Standard': 'color_white.jpg',
        'M4 Metal Thread': 'color_white_with_thread.jpg', 
      },
      'Black': {
        'Standard': 'color_black.jpg',
        'M4 Metal Thread': 'color_black_with_thread.jpg', 
      },
      'Neon yellow': {
        'Standard': 'color_neonyellow.jpg',
        'M4 Metal Thread': 'color_neonyellow_with_thread.jpg', 
      },
      'Neon orange': {
        'Standard': 'color_neonorange.jpg',
        'M4 Metal Thread': 'color_neonorange_with_thread.jpg', 
      },
      'Neon green': {
        'Standard': 'color_neongreen.jpg',
        'M4 Metal Thread': 'color_neongreen_with_thread.jpg', 
      }
    }
};
  
const GoalkeeperFigure = ({ onAddItem }) => {
  const [figureColor, setFigureColor] = useState('Light grey');
  const [figureType, setFigureType] = useState('Standard');

  const figureTypeOptions = [
    { value: 'Standard', label: 'Standard (€10)' },
    { value: 'M4 Metal Thread', label: 'With M4 Metal Thread (€12)' },
  ];

  return (
    <div>
      <label htmlFor="quantity">Quantity:</label><br />
      <input type="number" id="quantity" min="1" defaultValue="1" /><br /><br />

      <div className="selection figure-selection"> 
        <div>
          <label htmlFor="figureColor">Figure colour:</label><br />
          <select id="figureColor" value={figureColor} onChange={e => setFigureColor(e.target.value)}>
            <option value="Light grey">Light grey</option>
            <option value="White">White</option>
            <option value="Black">Black</option>
            <option value="Neon yellow">Neon yellow</option>
            <option value="Neon orange">Neon orange</option>
            <option value="Neon green">Neon green</option>
          </select>
        </div>
        <br/>

        <div>
          <label htmlFor="figureType">Figure type:</label><br />
          <select id="figureType" value={figureType} onChange={e => setFigureType(e.target.value)}>
            {figureTypeOptions.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <br/>

        {/* Display the image based on the selected color */}
        {figureColor && (
          <div><img src={imagePaths.figureColor[figureColor][figureType]} alt={`Turtle Goalkeeper in ${figureColor} ${figureType}`} /></div>
        )}
      </div>
      <br />

      <button onClick={() => 
        onAddItem({ 
          quantity: parseInt(document.getElementById('quantity').value) || 1, 
          figureColor, 
          figureType
        }) 
      }>
        Add to Cart
      </button>
    </div>
  );
};

export default GoalkeeperFigure;
